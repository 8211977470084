html,
body {
	overflow-x: hidden;
}

:root {
	--clr-primary: #0085ff;

	--clr-dark: #212121;
	--clr-dark-2: #535a5b;
	--clr-grey: #b5cbd2;
	--clr-grey-light: #d2e5e2;
	--clr-light: #ffffff;
	--clr-footer: #212121;
	--clr-path: #f0f0f0;

	/* Font - family, weight, size */
	--ff-heading: "Della Respira", serif;
	--ff-primary: "Poppins", sans-serif;
	--fw-bold: 600;
	--fs-300: 1rem;
	--fs-350: 1.25rem;
	--fs-400: 1.5rem;
	--fs-heading-3: 2.8rem;
	--fs-heading-1: 3.4rem;
	--fs-heading-2: 4.5rem;
	--fs-huge: 10rem;
}

body.dark-mode {
	--clr-light: #212121;
	--clr-dark: #f5f5f5;
	--clr-dark-2: #f5f5f5;
	--clr-footer: #2b2b2b;
	--clr-path: #333030;
}

body.dark-mode .logo-img {
	filter: hue-rotate(180deg) invert(1);
}

/* ---- CSS RESETS --- */
*,
*::after,
*::before {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
	font: inherit;
}

button {
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
}

img {
	user-select: none;
}

a {
	text-decoration: none;
	cursor: pointer;
}

body {
	text-rendering: optimizeSpeed;
	font-family: var(--ff-primary);
	color: var(--clr-dark);
	background-color: var(--clr-light);
	padding-top: 1.8rem;
	padding-inline: 1.8rem;
	line-height: 1.6;
	opacity: 0;
}

@media (min-width: 82em) {
	body {
		padding-inline: 6rem;
	}
}

/* ---- Navigation ---- */
.primary-nav,
.nav-list,
.logo-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.logo-container {
	position: relative;
	letter-spacing: 1px;
}

.logo-img {
	width: 310px;
	margin-right: 10px;
}

.nav-list {
	list-style: none;
	gap: 2.6rem;
	position: relative;
}

.nav-list li {
	cursor: pointer;
}

.menu-icon {
	padding: 0.6rem;
	display: block;
	position: relative;
	z-index: 11;
}

.menu-icon::after,
.menu-icon::before {
	content: "";
	display: block;
	width: 22px;
	height: 2px;
	background-color: var(--clr-dark-2);
	transition: transform 0.4s;
}

.menu-icon:before {
	width: 14px;
	margin-bottom: 4px;
}

.menu-icon.active:before {
	transform: rotate(-45deg) translateY(4px);
	width: 22px;
}

.menu-icon.active:after {
	transform: rotate(45deg) translateY(-4px);
}

.menu-container {
	width: max-content;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1.5rem 0;
	position: absolute;
	top: 100%;
	z-index: 10;
	visibility: hidden;
}

.menu-bg {
	content: "";
	position: absolute;
	width: 600px;
	height: 600px;
	border-radius: 100%;
	background-color: var(--clr-path);
	top: -250px;
	right: -350px;
	z-index: 1;
	visibility: hidden;
}

.nav-link {
	border-radius: 4px;
	color: var(--clr-dark-2);
	outline: none;
	width: fit-content;
	position: relative;
	z-index: 1;
}

.nav-link::after,
.nav-link::after {
	content: "";
	position: absolute;
	display: block;
	width: 100%;
	height: 1px;
	background-color: var(--clr-dark);
	bottom: -8px;
	right: 0;
	transform: scaleX(0);
	transition: transform 0.2s cubic-bezier(0, -0.01, 1, 0.4);
}

.nav-link:hover::after,
.nav-link:focus::after {
	transform: scaleX(1);
}

.nav-link::before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	position: absolute;
	top: calc(50% - 5px);
	left: -16px;
	transition: all 0.3s;
}

.nav-link.active {
	color: var(--clr-dark);
}

.nav-link.active::before {
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
	background: var(--clr-primary);
}

.bulb {
	transform: rotate(180deg);
	font-size: 25px;
	position: fixed;
	color: var(--clr-dark-2);
	z-index: 20;
}

.bulb::before,
.bulb::after {
	content: "";
	width: 2px;
	height: 20rem;
	position: absolute;
	background-color: var(--clr-dark-2);
	left: 42%;
	top: 60%;
}

.bulb:after {
	background-color: #fff;
	filter: blur(30px);
	width: 3rem;
	height: 3rem;
	top: -35%;
	left: calc(50% - 1.5rem);
	z-index: -1;
}

.hidden-link {
	display: none;
}

/* ----  Hero-Section ---- */
.main-heading {
	position: relative;
	padding-top: 2.4rem;
	transition: color 0.4s;
	z-index: 10;
}

.quote-left {
	position: absolute;
	top: 10px;
	left: -25px;
	font-size: var(--fs-400);
	color: var(--clr-grey);
	opacity: 0.5;
}

.hero-section {
	display: flex;
	padding-block: 2.5rem;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 6rem;
}

.hero-section-content {
	width: 48vw;
	display: flex;
	flex-direction: column;
	gap: 9rem;
}

.hero-section-content p {
	width: 80%;
}

/* Image Carousal */
.path-container {
	position: absolute;
	top: -8%;
	right: 13%;
	z-index: -10;
}

.carousal-circle {
	--stroke: 6px;
	position: absolute;
	background-color: var(--clr-dark);
	width: 440px;
	aspect-ratio: 1/1;
	border-radius: 100%;
	border: var(--stroke) solid #777;
	z-index: -5;
	overflow: hidden;
	visibility: hidden;
}

.carousal-images,
.benefits-images {
	display: flex;
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	gap: 10%;
}

.benefits-images {
	opacity: 0;
}

.carousal-image {
	width: 100%;
	flex-shrink: 0;
	object-fit: cover;
	object-position: center;
	border-radius: 3rem;
}

/*------- SECTIONS --------*/
/*------- Section-Benefits --------*/
.section {
	padding-block: 5.5rem;
}

.section-benefits ol {
	padding-left: 1rem;
	margin-left: var(--fs-heading-2);
}

.section-benefits ol li::marker {
	font-size: var(--fs-heading-2);
}

.section-benefits {
	display: flex;
	flex-direction: column;
	gap: 250px;
}

.benefits-content {
	--height: 250px;
	max-width: 800px;
	width: 95vw;
	height: var(--height);
	display: grid;
	overflow: hidden;
	position: relative;
}

.benefits-list {
	position: absolute;
	width: 100%;
}

.benefits-list .list-item {
	width: 100%;
	flex-shrink: 0;
	height: var(--height);
}

.benefits-list .list-item p {
	width: 80%;
	max-width: 700px;
}

.section-who-can-benefit ol {
	margin-left: var(--fs-heading-3);
	font-size: var(--fs-400);
}

.section-who-can-benefit ol li::marker {
	font-size: var(--fs-400);
}

.section-who-can-benefit .list-item {
	padding: 2rem 0;
	width: 43vw;
}

/* ----- SERVICES Section */
.section-services {
	padding-top: 0;
}

.services-content {
	display: flex;
	position: relative;
	user-select: none;
	width: max-content;
	left: 10%;
}

.section-services .more-items {
	margin-top: 2rem;
}

.more-items {
	margin-block: auto;
	user-select: none;
	cursor: pointer;
	position: relative;
	z-index: 10;
}

.service-item {
	max-width: 900px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 3rem;
	height: 70vmin;
}

.service-img-container {
	overflow: hidden;
	height: 100%;
	aspect-ratio: 3/4;
}

.service-img-container img {
	object-fit: cover;
	width: 100%;
	height: 100%;
	object-position: center;
	border-radius: 4px;
}

.service-item-content {
	display: flex;
	flex-direction: column;
	max-width: 21rem;
	height: 100%;
}

.item-heading {
	line-height: 1.1;
}

.item-count {
	font-size: var(--fs-huge);
	margin-left: calc(var(--fs-huge) * 1.5);
	line-height: 0.8;
	color: var(--clr-grey-light);
	list-style-type: decimal-leading-zero;
	padding-top: 1rem;
	margin-top: auto;
}

.service-item-para {
	margin-top: 1.5rem;
	line-height: 1.5;
}

.item-seperator {
	width: 2px;
	height: 120%;
	background-color: var(--clr-grey);
	transform: rotate(4deg);
	margin-right: 4vmin;
}

/* -----  TESTIMONIAL Section ---- */
.testimonial-content .wrapper:not(:last-child) {
	padding-bottom: 2rem;
}

.testimonial-row {
	list-style: none;
	display: flex;
	gap: 2rem;
}

.testimonial-row li {
	width: clamp(340px, 50%, 800px);
	aspect-ratio: 4/3;
	flex-shrink: 0;
}

.testimonial-row .testimonial {
	padding: 0 3rem;
	line-height: 2;
	letter-spacing: 1px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.testimonial-row img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.testi-msg::before {
	content: "\f10d";
	font-family: "Font Awesome 6 Free";
	position: relative;
	bottom: 10px;
	right: 10px;
	color: var(--clr-grey);
}

/* -----  CQC Section ---- */
#section-cqc {
	/* position: absolute; */
}

#section-cqc div.cqc-widget {
	padding: 1rem 0;
	border: none !important;
}

#section-cqc div.cqc-widget.cleanslate .cqc-container {
	background: var(--clr-light) !important;
}
#section-cqc .widget-button-new-style {
	color: var(--clr-dark);
	margin-left: -.4rem;
}
#section-cqc .cqc-widget-normal {
	padding: 1rem 1rem;
	margin-top: .5rem;
	background: var(--clr-light) !important;
	border: 2px solid var(--clr-dark-2) !important;
	border-radius: 10px !important;
}
.cqc-widget-footer {margin-bottom: 1rem;}
.cqc-widget-overall {
	font-weight: bold;
	position: relative;
}
.cqc-widget-overall.good::after {
	content: "";
	width: 16px;
	height: 16px;
	top: 1px;
	transform: translateX(5px);
	border-radius: 100%;
	background-color: green;
	position: absolute;
}

#section-cqc .cqc-widget-inner,
#section-cqc .cqc-widget-overall {
	color: var(--clr-dark) !important;
}

body.dark-mode #section-cqc img {
	filter: invert(1);
}


/* ----- SHOWCASE Section ------*/
.section-showcase {
	min-height: 100vmin;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
}

.showcase-head {
	user-select: none;
	position: relative;
	cursor: pointer;
}

.showcase-head+.plus,
.click {
	cursor: pointer;
	color: var(--clr-grey-light);
	transition: transform 0.2s ease-out;
	z-index: 1;
}

.showcase-head .click {
	position: absolute;
	top: -30px;
	left: 40px;
}

.showcase-head:hover+.plus {
	transform: rotate(-90deg) scale(2);
}

.showcase-head:hover .click {
	transform: scale(1.4);
}

.showcase-images {
	position: absolute;
	display: flex;
	gap: 3rem;
	visibility: hidden;
	left: 50%;
}

.showcase-images img {
	width: 450px;
	aspect-ratio: 3/4;
	object-fit: cover;
	object-position: 100% 50%;
}

.showcase-images .arrow {
	margin-block: auto;
}

/* ----- Section Appointment  ---- */
.section-appointment {
	display: flex;
	justify-content: space-evenly;
	gap: 1.5rem;
}

.appointment-form .form-fields {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	min-width: 450px;
}

.appointment-form .form-control {
	border-bottom: 2px solid var(--clr-grey);
}

.appointment-form .form-control li {
	font-size: var(--fs-400);
	font-weight: var(--fw-bold);
	list-style-type: decimal-leading-zero;
}

.appointment-form .form-control li::marker {
	font-size: var(--fs-300);
}

.appointment-form .form-control .label {
	margin-left: 1.5rem;
}

.appointment-form .form-control input {
	background-color: transparent;
	border: none;
	outline: none;
	width: 100%;
	padding: 0.8rem 0 0.8rem 1.5rem;
	color: var(--clr-dark-2);
}

.appointment-form .btn {
	align-self: flex-start;
}

/* ----- INFO Section ------*/
.section-info {
	padding-top: 1rem;
	display: flex;
	justify-content: space-evenly;
	gap: 5rem;
}

.info-location {
	width: 400px;
	height: 630px;
	position: relative;
}

.info-location .location-dot {
	position: absolute;
	top: 63.5%;
	right: 25%;
	cursor: pointer;
}

.info-location .gmap {
	position: absolute;
	top: 42%;
	right: 6%;
	aspect-ratio: 1/1;
	object-fit: cover;
	overflow: hidden;
	transform: translate(20%, -100%);
	border-radius: 100%;
	border: 3px solid var(--clr-grey);
	opacity: 0;
}

/* ----- FOOTER ------------------- */
.footer {
	display: flex;
	justify-content: space-between;
	padding: 3rem 3rem;
	background-color: var(--clr-footer);
	box-shadow: 10vw 0 0 var(--clr-footer), -10vw 0 0 var(--clr-footer);
	position: relative;
}

.footer .nav-list {
	justify-content: space-evenly;
	align-items: flex-start;
}

.footer .nav-link:after {
	background-color: #f5f5f5;
}

.footer .nav-link {
	color: #f5f5f5;
	background-color: transparent;
}

.footer .icon {
	color: #f5f5f5;
}

.footer-logo {
	color: #f5f5f5;
	padding-top: 1rem;
	width: 90%;
	border-right: 3px solid #383838;
}

.footer-logo .logo-img {
	filter: hue-rotate(180deg) invert(1);
	margin-top: -17px;
}

.footer-desc {
	color: var(--clr-grey-light);
	margin-top: 1rem;
	width: 35ch;
}

.footer-links {
	flex-basis: 100%;
	display: flex;
	justify-content: space-evenly;
}

/* ----- ABOUT US PAGE STYLES ----------- */
.section-about {
	display: flex;
	width: max-content;
	gap: 5rem;
	align-items: flex-start;
	padding: 1.7rem 0 2rem 1rem;
}

.abt-item {
	height: 80vh;
	width: max-content;
	display: flex;
	align-items: flex-end;
	gap: 5rem;
	padding-right: 2rem;
	border-right: 1px solid var(--clr-dark-2);
}

.abt-heading,
.abt-content {
	max-width: 500px;
}

.abt-img-container {
	flex-shrink: 0;
	overflow: hidden;
	width: 90vw;
	max-width: 440px;
	height: 100%;
}

.abt-item img {
	width: 100%;
	height: 100%;
	object-position: center;
	object-fit: cover;
}

/* ----- CONTACT US PAGE STYLES ----------- */
.section-contact {
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.section-contact .gmap {
	overflow: hidden;
	border-radius: 100%;
	max-width: 300px;
	flex-shrink: 0;
	margin: auto;
	margin-top: 1rem;
	border: 4px solid var(--clr-grey);
}

.section-contact .gmap img {
	width: 100%;
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: center;
	scale: 1.05;
	pointer-events: none;
	transition: all 1s;
}

.section-contact .gmap img:hover {
	scale: 1.3;
}

/* ----- Reusable components  ---- */
/* Buttons */
.btn {
	padding: 0.2em 1.2em 0.2em 0.7em;
	color: var(--clr-dark-2);
	border-radius: 4.1em;
	background: transparent;
	outline-offset: 0.4rem;
	font-size: var(--fs-350);
	user-select: none;
	position: relative;
	transition: translate 0.3s;
	backface-visibility: hidden;
}

.btn:hover {
	translate: 0 -5px;
}

/* For arrow */
.btn::after {
	--size: 24px;
	content: "";
	padding: 5px;
	display: block;
	position: absolute;
	width: var(--size);
	height: var(--size);
	bottom: 0;
	right: 0;
	transform: translate(20%, 20%);
}

.btn-1 {
	border: 2px solid var(--clr-primary);
}

.btn-1:after {
	background: url("../assets/arrow.svg") no-repeat center;
	background-color: var(--clr-light);
}

.btn-2 {
	border: 2px solid #6ccb4e;
}

.btn-2:focus-visible {
	outline: 2px solid #6ccb4e;
}

.btn-2:after {
	background: url("../assets/arrow-g.svg") no-repeat center;
	background-color: var(--clr-light);
}

/* ---- Utility: Fonts, Color, Flex   ---- */
.heading-1 {
	font-family: var(--ff-heading);
	font-size: var(--fs-heading-1);
	color: var(--clr-dark-2);
	line-height: 1.2;
}

.heading-2 {
	font-size: var(--fs-heading-2);
	font-family: var(--ff-heading);
	color: var(--clr-dark-2);
}

.heading-3 {
	font-size: var(--fs-heading-3);
}

.text-lg {
	font-size: var(--fs-400);
}

.text-md {
	font-size: var(--fs-350);
}

.text-sm {
	font-size: var(--fs-300);
}

.bold {
	font-weight: var(--fw-bold);
}

.clr-primary {
	color: var(--clr-primary);
}

.clr-dark {
	color: var(--clr-dark);
}

.clr-dark-2 {
	color: var(--clr-dark-2);
}

.clr-grey {
	color: var(--clr-grey);
}

.clr-light {
	color: var(--clr-light);
}

.flex {
	display: flex;
	align-items: center;
}

.flex-cl {
	display: flex;
	flex-direction: column;
}

.gap-sm {
	gap: 0.5rem;
}

.gap-md {
	gap: 2rem;
}

.mb-lg {
	margin-bottom: 3.5rem;
}

.mb-md {
	margin-bottom: 1.5rem;
}

.mb-sm {
	margin-bottom: 3px;
}

.ml-lg {
	margin-left: 3rem;
}

.ml-md {
	margin-left: 1.5rem;
}

.ml-sm {
	margin-left: 0.5rem;
}

.clip-below-text {
	clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

/* ---- Cursor --- */
#ball {
	width: 50px;
	height: 50px;
	position: fixed;
	top: 0;
	left: 0;
	border: 2px solid var(--clr-grey);
	border-radius: 50%;
	pointer-events: none;
	display: none;
}

/* For Animations */
.word {
	display: inline-block;
}

.char {
	display: inline-block;
	margin-left: -1px;
}

.bar {
	position: fixed;
	width: 35%;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: var(--clr-primary);
	z-index: 100;
	transform: translateY(100%);
	visibility: hidden;
}

.bar:nth-of-type(2) {
	left: 33%;
}

.bar:nth-of-type(3) {
	right: 0;
	left: auto;
}

/* 1000px */
@media (max-width: 62.5em) {
	.hero-section {
		margin-bottom: 0;
	}

	.carousal-circle {
		width: 360px;
	}

	.hero-section-content {
		gap: 4rem;
	}

	.path-container {
		top: -10%;
		right: 10%;
	}

	.section-appointment {
		display: block;
	}

	.section-appointment .section-heading {
		display: flex;
		margin-bottom: 2rem;
		flex-wrap: wrap;
	}

	.appointment-form .form-fields {
		padding-inline: 2rem;
		min-width: 380px;
	}

	.section-info {
		gap: 0;
	}

	.location-dot {
		display: none;
	}

	.footer {
		padding-inline: 1.5rem;
	}

	/* About us */
	.abt-item:first-child {
		flex-direction: column;
		align-items: flex-start;
		gap: 0;
	}

	.abt-item:first-child .abt-img-container {
		height: 70%;
	}

	.abt-text {
		max-width: 380px;
		margin-bottom: 5vh;
	}
}

@media (min-height: 50em) {
	.abt-item {
		align-items: center;
	}
}

/* 832px */
@media (max-width: 52em) {
	.footer {
		padding: 2rem 1rem;
		display: block;
	}

	.footer-logo {
		border: none;
		margin-bottom: 1rem;
		width: 100%;
	}

	.footer-desc {
		width: 100%;
	}

	.footer-links {
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.footer-links .nav-list {
		width: 45%;
		height: min-content;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 1rem;
		flex-wrap: wrap;
	}

	.footer-links .nav-link {
		font-weight: normal;
	}

	.footer-links .nav-link::before {
		display: none;
	}
}

/* 750px */
@media (max-width: 46.875em) {
	html {
		font-size: 86%;
	}

	:root {
		--fs-heading-1: 3.2rem;
		--fs-heading-2: 4rem;
	}

	.menu-container {
		right: 0;
	}

	.path-container {
		right: 2%;
	}

	.carousal-circle {
		width: 320px;
	}

	.section-benefits {
		gap: 330px;
	}

	.info-location,
	.info-location .map {
		width: 350px;
		height: 500px;
	}

	/* Contact us */
	.section-contact {
		flex-direction: column;
	}

	.section-contact form {
		width: 90vw;
		margin-bottom: 2rem;
	}

	.section-contact .more-info-container {
		flex-direction: row;
		align-items: center;
	}

	.section-contact .more-info {
		flex-direction: row;
		flex-wrap: wrap;
		height: min-content;
	}
}

/* 600px */
@media (max-width: 37.5em) {
	:root {
		--fs-heading-2: 3.6rem;
		--fs-huge: 5rem;
	}

	.primary-nav .nav-list li:nth-of-type(1),
	.primary-nav .nav-list li:nth-of-type(2) {
		display: none;
	}

	.hidden-link {
		display: block;
	}

	.menu-container>* {
		text-align: right;
	}

	.hero-section-content {
		width: 95%;
		gap: 24rem;
		padding-bottom: 3rem;
	}

	.hero-section-content .btn-group {
		flex-direction: column;
		align-items: flex-start;
		gap: 1rem;
	}

	.main-heading {
		padding-top: 0.5rem;
	}

	.path-container {
		right: 14%;
		top: 8%;
	}

	.section-benefits {
		padding-top: 1rem;
	}

	.benefits-content {
		--height: 200px;
	}

	#path {
		stroke: var(--clr-path);
	}

	.section-who-can-benefit {
		padding-top: 0;
	}

	.section-who-can-benefit .list-item {
		line-height: 1.2;
		padding-block: 2.5rem;
	}

	.section-who-can-benefit ol {
		font-size: var(--fs-350);
	}

	.services-content {
		gap: 3rem;
	}

	.testimonial-row {
		gap: 0;
	}

	.showcase-images img {
		width: 350px;
	}

	.section-info {
		flex-direction: column;
		gap: 8rem;
	}

	.section-info .section-heading {
		display: flex;
	}

	.info-contact {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.info-location {
		margin-inline: auto;
		height: 350px;
	}

	.info-location .gmap {
		right: unset;
		top: 62%;
	}

	.footer {
		padding-inline: 0;
	}

	/* Contact Us */
	.section-contact .more-info-container {
		flex-direction: column;
	}
}

/* 480px */
@media (max-width: 30em) {
	:root {
		--fs-heading-1: 3.2rem;
		--fs-heading-2: 2.4rem;
		--fs-heading-3: 2rem;
	}

	.logo-img {
		width: 220px;
	}

	.path-container {
		top: 9%;
	}

	.carousal-circle {
		width: 300px;
		z-index: 3;
	}

	.section-benefits {
		gap: 330px;
	}

	.section-testimonial {
		padding-top: 2rem;
	}

	.main-heading {
		padding-top: 2rem;
	}

	.mb-md {
		margin-bottom: 1rem;
	}

	.mb-lg {
		margin-bottom: 2rem;
	}

	.abt-text {
		max-width: 300px;
	}

	.abt-heading {
		max-width: 90vw;
	}
}